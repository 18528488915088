import React from 'react';
import { Box, Badge, Container, Grid, GridItem, Heading, Text } from '@chakra-ui/react';

import logo from '../assets/img/k2logo.png';

export const PageHeader = () => {
    return (
        // <BackgroundVideo>
            <Box className="header-container" w="100%" py={20}>
                <Container maxW="container.lg">
                    <Grid templateColumns="repeat(2, 1fr)">
                        <GridItem className="header-inner-contents" rowSpan={2} colSpan={1}>
                            <Box w="100%" mb={10}>
                                <img width="73" height="80" className="m4" alt="logo" src={logo} />
                            </Box>

                            <Badge fontSize="1em" mb={2}>Jetzt neu!</Badge>
                            <Heading mb={5}>Indivduelle Prognose für den Gewichtsverlust</Heading>
                            <Text fontSize={20}>Die Kilo Tropfen-Anwendung ist erfolgreich und durch Studien belegt. Auf dieser Datengrundlage können wir die Zeit berechnen, die Sie für die Erreichung Ihres gewünschten Gewichts vermutlich benötigen.</Text>
                        </GridItem>
                    </Grid>
                </Container>
            </Box>
        //</BackgroundVideo>
    )
}
